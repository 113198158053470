<template>
  <div id="app">
    <div class="content">
      <h1 class="lavishly-yours">miss-hadid</h1>
      <hr class="line">
      <p class="est">est. 2024</p>
      <p class="description">
        modernist inspired : minimalist design<br>
        <span class="bio-text">
          miss-hadid is the fashion-forward epicenter for modern women. based in los angeles, our brand fuses modernist inspiration with minimalist design, echoing the clean lines and innovative spirit of contemporary architecture. we break fashion boundaries with simplicity and sustainability, crafting timeless pieces with cutting-edge advancements and sustainable materials. like well-designed buildings, our garments enhance natural beauty with perfect structure and fluidity. featuring clean lines, neutral palettes, and essential details, our wardrobe is effortlessly chic and versatile. miss-hadid represents the intersection of fashion, technology, and sustainability, making each piece a statement of identity and aspiration.
        </span>
      </p>
      <button class="view-collection">view collection</button>
    </div>
    <footer>
      <p>summer 2024</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lavishly+Yours&display=swap');

html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: #C5C2C2;
  font-family: 'Azeret Mono', monospace;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.lavishly-yours {
  margin: 0;
  font-size: 3em;
  font-family: 'Lavishly Yours', cursive;
  color: #C5C2C2;
}

.line {
  width: 100%;
  max-width: 200px;
  border: 1px solid #C5C2C2;
  margin: 10px 0;
}

.est {
  margin: 0;
  font-size: 0.8em;
  color: #C5C2C2;
}

.description {
  margin: 20px 0;
  color: #C5C2C2;
  line-height: 1.5;
}

.bio-text {
  display: block;
  margin-top: 10px;
}

.view-collection {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #C5C2C2;
  color: black;
  border: none;
  cursor: pointer;
  font-family: 'Azeret Mono', monospace;
  text-transform: uppercase;
}

.view-collection:hover {
  background-color: #9b9999;
}

footer {
  position: absolute;
  bottom: 20px;
}

footer p {
  margin: 0;
  font-size: 0.6em;
  color: #C5C2C2;
}

/* Responsive design */
@media (max-width: 600px) {
  .lavishly-yours {
    font-size: 2em;
  }

  .line {
    max-width: 150px;
  }

  .est {
    font-size: 1em;
  }

  .description {
    font-size: 0.9em;
  }

  .view-collection {
    margin-top: 15px;
    padding: 8px 16px;
    font-size: 0.9em;
  }

  footer p {
    font-size: 1.2em;
  }
}
</style>
